import { render, staticRenderFns } from "./forum.vue?vue&type=template&id=43da2c24&scoped=true&"
import script from "./forum.vue?vue&type=script&lang=js&"
export * from "./forum.vue?vue&type=script&lang=js&"
import style0 from "./forum.vue?vue&type=style&index=0&id=43da2c24&lang=less&scoped=true&"
import style1 from "./forum.vue?vue&type=style&index=1&id=43da2c24&scoped=true&lang=css&"
import style2 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43da2c24",
  null
  
)

export default component.exports