<template>
  <div>
    <div class="content not-select">
      <div class="center">
        <div class="left" v-html="html" />
        <div class="comments">
          <div v-for="item in comments" :key="item.id">
            <div class="hander">
              <span class="ava-name">
                <span class="ava">{{
                  item.userinfo.userName.substring(0, 1)
                }}</span>
                <span class="name">{{ item.userinfo.userName }}</span>
              </span>
              <span class="time">{{ timeTranslate(item.createdAt) }}</span>
            </div>
            <div class="content">
              {{ item.content }}
            </div>
            <div class="bottom">
              <span>
                <i class="el-icon-star-on" />
                <span class="reword">回复评论</span>
              </span>
            </div>
          </div>
          <div class="send-comment">
            <el-input
              v-model="textarea"
              type="textarea"
              autosize
              placeholder="请输入评论"
            />
            <span class="send" @click="createComment">发布评论</span>
          </div>
        </div>
      </div>
    </div>
    <footer-page class="footer" />
  </div>
</template>

<script>
import FooterPage from '../../components/Footer/index.vue'
// import { timeTranslate } from '../../tools/initTime'
export default {
  components: { FooterPage },
  data: () => ({
    id: null,
    html: '',
    comments: undefined,
    textarea: ''
  }),
  created() {
    // console.log(this.$route)
    const id = this.$route.params.id
    if (id) {
      this.id = id
    } else {
      this.$router.push({ name: 'Welcome' })
    }
  },
  mounted() {
    // this.initBankCard()
    this.getAllTalks()
  },
  methods: {
    async getAllTalks() {
      const imgURL =
        'https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/order-images/1702cd9f837848948dc8814a4b8fa0f0.jpg'
      const { results = [] } = await this.$store.dispatch('forum/getAllTalks', {
        status: 1,
        id: this.id
      })
      for (const item of results) {
        item.imgURL = imgURL
      }
      results[0].content = await results[0].content.replace(
        /style=\"\"/gm,
        `style="max-width: 100%;"`
      )
      // console.log(results[0].content)
      this.html = results[0].content
      this.comments = results[0].comments || []
    },
    timeTranslate(val) {
      var utc = require('dayjs/plugin/utc')
      this.dayjs.extend(utc)
      return this.dayjs(val).utc().utcOffset(8).format('YYYY-MM-DD HH:mm:ss')
    },
    async createComment() {
      if (!this.textarea) {
        this.$message({
          type: 'warning',
          message: '请先填写评论'
        })
        return
      }
      const data = {
        forumID: this.id,
        content: this.textarea,
        father: 0
      }
      const { code = 0 } = await this.$store.dispatch(
        'forum/createComment',
        data
      )
      if (code) {
        this.getAllTalks()
      }
    },

    goToIndex() {
      this.$router.push('/Paper')
    },
    goToEnglish() {
      this.$router.push('/AllTools')
    },
    goToAddTalks(where) {
      const params = where === 'Markdown' ? 'Markdown' : 'Normal'
      this.$router.push({ name: 'Forum', query: { type: params }})
    }
  }
}
</script>

<style lang="less" scoped>
@hightBlue: rgb(0, 122, 255);
.initBox {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 3px, rgba(0, 0, 0, 0.08) 0px 2px 8px;
  background-color: #fff;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1200px;
    min-height: calc(100vh - 60px);
    padding-bottom: 20px;
    overflow: hidden;

    .left {
      width: 1200px;
      background-color: #fff;
      padding-top: 20px;
      padding: 20px;

      img {
        width: 100%;
      }
    }

    .comments {
      width: 100%;
      background-color: #fff;
      margin-top: 10px;
      padding: 16px;

      .hander {
        display: flex;
        justify-content: space-between;
        height: 30px;

        .ava-name {
          .ava {
            display: inline-block;
            width: 30px;
            height: 30px;
            background-color: cadetblue;
            line-height: 30px;
            margin-right: 10px;
            border-radius: 50%;
            font-weight: 700;
            text-align: center;
          }
        }
      }

      .content {
        display: block;
        width: 100%;
        background-color: #fff;
        text-align: left;
        padding: 20px;
        border-bottom: 1px solid #b9b9b9;
      }

      .bottom {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;

        .reword {
          padding: 5px 10px;
          background-color: #b9b9b9;
          font-size: 12px;
          color: #ffffff;
          border-radius: 5px;
          transition: all 0.3s;
        }

        .right-send {
          display: flex;
        }

        .send:hover,
        .reword:hover {
          background-color: skyblue;
        }
      }

      .send-comment {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .send {
          display: flex;
          padding: 5px 10px;
          background-color: #b9b9b9;
          font-size: 12px;
          color: #ffffff;
          border-radius: 5px;
          transition: all 0.3s;
          cursor: pointer;
          width: 100px;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        }
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
  }
}
</style>

<style scoped>
@import "../../assets/CSS/common.css";
@import "../../assets/CSS/style.css";
.left >>> pre >>> code {
  background-color: var(--w-e-textarea-slight-bg-color);
  border: 1px solid var(--w-e-textarea-slight-border-color);
  border-radius: 4px 4px;
  display: block;
  font-size: 14px;
  padding: 10px;
  text-indent: 0;
}
</style>

<style src="@wangeditor/editor/dist/css/style.css"></style>
