<template>
  <div class="content">

    <a
      target="_blank"
      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010202000582"
    ><img src="../../assets/images/BeiAn.png">
      <p>桂公网安备 45010202000582号</p></a>
    <span class="line" />
    <a
      href="https://beian.miit.gov.cn/"
      target="_blank"
    >桂ICP备2022003081号</a>

  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.content {
  /* position: sticky;
  bottom: 0px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  font-size: 10px;
  text-align: center;
}
.content a {

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #939393;
  height: 15px;
}
.content p {
  display: inline-block;
  margin: 0px 0px 0px 5px;
  color: #939393;
}
img {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.line {
  padding: 2px 0;
  margin: 0 10px;
  height: 12px;
  border-left: 1px solid #939393;
}
</style>
